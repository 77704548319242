import { useConversationUrl } from '@hooks/use-conversation-url';
import { useAppSelector } from '@redux/hooks';
import type { InlineRfqSourceContent } from '@typings/sourceContent';
import { DisplayButton, DisplayLink } from '@xo-union/tk-ui-links';
import { Body1 } from '@xo-union/tk-ui-typography';
import React from 'react';
import InlineRfqModal from '../../pages/Storefront/containers/InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import { useInlineRfqForm } from '../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import { generateDefaultField } from '../../pages/Storefront/containers/InlineRFQ/utils';
import Styles from './highlightedRequest.scss';
import { HighlightedRequestIcon } from './highlightedRequestIcon';

export type HighlightedRequestProps = {
	messageText: string;
	buttonText: string;
	initiator: InlineRfqSourceContent;
	hideIfLead?: boolean;
};

export const HighlightedRequest: React.FC<HighlightedRequestProps> = ({
	messageText,
	buttonText,
	initiator,
	hideIfLead,
}) => {
	const vendorId = useAppSelector((state) => state.vendor.vendor?.id);
	const conversationUrl = useConversationUrl(vendorId);

	const localFormContext = useInlineRfqForm({
		freeTextLabel: '',
		headerText: messageText,
		textAreaInitialValueOverride: generateDefaultField('textarea'),
		initiator,
	});

	if (hideIfLead && conversationUrl) {
		return null;
	}

	return (
		<>
			<InlineRfqModal context={localFormContext} />
			<div className={Styles.container}>
				<HighlightedRequestIcon />
				<div className={Styles.rightContent}>
					<Body1>{messageText}</Body1>
					{conversationUrl ? (
						<DisplayLink
							color="secondary"
							href={conversationUrl}
							size="md"
							className={Styles.button}
						>
							View Conversation
						</DisplayLink>
					) : (
						<DisplayButton
							onClick={localFormContext.openModal}
							className={Styles.button}
							type="button"
						>
							{buttonText}
						</DisplayButton>
					)}
				</div>
			</div>
		</>
	);
};
