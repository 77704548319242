import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import type { Decorated } from 'types/vendor';
import AnalyticsConstants from '../../../../constants/analytics';
import VendorAnalytics from '../../../decorators/vendorAnalytics';
import type { AvailabilityCalendarAction } from '../types';

const getVendorAnalytics = (vendor: Decorated) => {
	const vendorAnalytics = new VendorAnalytics(vendor);
	return {
		event: AnalyticsConstants.WIDGET_CLICKED,
		properties: {
			sourceContent: AnalyticsConstants.PHOTO_GALLERY,
			sourcePage:
				vendor.purchaseStatus === 'PAID'
					? AnalyticsConstants.PAID_STOREFRONT
					: AnalyticsConstants.UNPAID_STOREFRONT,
			...vendorAnalytics.baseEventProps(),
		},
	};
};

export const useTrackAvailabilityCalendar = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);

	const { event, properties } = useMemo(() => {
		return getVendorAnalytics(vendor);
	}, [vendor]);
	const { track } = useAnalyticsContext();

	return useCallback(
		(action: AvailabilityCalendarAction) => {
			track({
				event,
				properties: {
					action,
					...properties,
				},
			});
		},
		[track, event, properties],
	);
};
