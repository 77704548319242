import { Packages } from '@components/detailed-pricing/Packages';
import { PricingCta } from '@components/detailed-pricing/PricingCta';
import { usePdfUrl } from '@components/pricing/hooks/use-pdf-url';
import { PricingPdf } from '@components/pricing/pdf';
import { useTrackStorefrontImpression } from '@hooks/useTrackStorefrontImpression';
import { useAppSelector } from '@redux/hooks';
import Icon from '@xo-union/tk-component-icons';
import { Body1, H3 } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React, { type FC } from 'react';
import type { CategoryCode } from 'types/Category';
import { CATEGORY_SINGULAR_ABBREVIATED_TERMS } from '../../../constants/categories';
import { useStorefrontFeatureBvcPlusPricing } from '../../feature-flags/hooks/Storefront/useStorefrontFeatureBvcPlusPricing';
import { packagesAtom } from '../../jotai/storefront/detailed-pricing';
import type { NavItemProps } from '../../pages/Storefront/containers/nav/types';
import Styles from './package-pricing.scss';

const NoPricingDetails = () => {
	return (
		<div className={Styles.noPricingDetailsWrapper}>
			<div className={Styles.noPricingDetails}>
				<Body1 bold>No pricing details yet</Body1>
				<Body1>Reach out to get a customized quote from this vendor.</Body1>
			</div>
			<PricingCta sourceContent="Packages" />
		</div>
	);
};

const PricingDetails = () => {
	const isBvcPlusDetailedPricing = useStorefrontFeatureBvcPlusPricing();
	const categoryCode = useAppSelector(
		(state) => state.vendor.vendor?.categoryCode as CategoryCode,
	);
	const singularTerm = CATEGORY_SINGULAR_ABBREVIATED_TERMS[categoryCode];
	const pricingDetailsText = `Starting prices may not include all fees a vendor may charge. Contact the ${singularTerm} for customized services and quotes.`;

	return (
		<div className={Styles.pdfCtaWrapper}>
			{!isBvcPlusDetailedPricing && (
				<PricingPdf text="Looking for more pricing info?">
					<div className={Styles.divider} />
				</PricingPdf>
			)}
			<div className={Styles.iconWrapper}>
				<Icon size="sm" name="info" />
				{pricingDetailsText}
			</div>
			<PricingCta sourceContent="Packages" />
		</div>
	);
};

export const PackagePricing: FC<NavItemProps> = () => {
	const isBvcPlusDetailedPricing = useStorefrontFeatureBvcPlusPricing();
	const packages = useAtomValue(packagesAtom);
	const pdfUrl = usePdfUrl();
	const hasPricingDetails = Boolean(
		isBvcPlusDetailedPricing
			? packages.length
			: packages.length || pdfUrl?.length,
	);
	const milestone = isBvcPlusDetailedPricing
		? 'Detailed Pricing Packages'
		: 'Packages';

	const trackingRef = useTrackStorefrontImpression(milestone);

	return (
		<div className={Styles.pricesAndPackages}>
			<H3 ref={trackingRef}>Prices & packages</H3>
			<Packages />
			{hasPricingDetails ? <PricingDetails /> : <NoPricingDetails />}
		</div>
	);
};
