import Icon from '@xo-union/tk-component-icons';
import { Body1, Body2 } from '@xo-union/tk-ui-typography';
import React, { type ComponentProps } from 'react';
import Styles from '../styles.scss';

interface Props {
	icon: ComponentProps<typeof Icon>['name'];
	text: string;
	textWrapper?: 'body1' | 'body2';
}

export const Highlight = ({ icon, text, textWrapper = 'body1' }: Props) => {
	return (
		<div className={Styles.highlight}>
			<Icon name={icon} size="md" className={Styles.highlightImage} />
			{textWrapper === 'body1' && <Body1>{text}</Body1>}
			{textWrapper === 'body2' && <Body2>{text}</Body2>}
		</div>
	);
};
