import { Highlight } from '@components/about-spotlight/components/highlight';
import { useAppSelector } from '@redux/hooks';
import type { Decorated } from '@typings/vendor';
import Icon from '@xo-union/tk-component-icons';
import { Body2 } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React from 'react';
import { Link } from 'react-scroll';
import {
	languagesAtom,
	startYearAtom,
	teamMembersAtom,
	totalMembersAtom,
} from '../../../../jotai/storefront/organization';
import DetailsHelper from '../../../../pages/Storefront/containers/details/helpers';
import Style from './about-highlights.scss';

const formatBusinessAttributes = (businessAttributes: string[]) => {
	if (businessAttributes.length === 1) return businessAttributes[0];

	const lastElement = businessAttributes[businessAttributes.length - 1];
	const otherElements = businessAttributes.slice(
		0,
		businessAttributes.length - 1,
	);

	const formattedOthers = otherElements.map((attr) =>
		attr.replace(' Business', ''),
	);

	return `${formattedOthers.join(', ')} and ${lastElement}`;
};

export const AboutHighlights = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const currentYear = new Date().getFullYear();
	const startYear = useAtomValue(startYearAtom);
	const totalMembers = useAtomValue(totalMembersAtom);
	const languages = useAtomValue(languagesAtom)?.join(', ');
	const hasTeamMembers = useAtomValue(teamMembersAtom)?.length > 0;

	const yearsInBusiness = startYear ? currentYear - startYear : 0;

	const flattenedDetails = DetailsHelper.detailsFlattener(vendor) as {
		'Business Attributes': string[];
	};

	const businessAttributes = flattenedDetails['Business Attributes'];

	if (
		businessAttributes?.length > 0 ||
		yearsInBusiness ||
		languages ||
		totalMembers
	) {
		return (
			<div className={Style.container} data-testid="about-highlights">
				{businessAttributes?.length > 0 && (
					<Highlight
						textWrapper="body2"
						icon="vendors"
						text={formatBusinessAttributes(businessAttributes)}
					/>
				)}
				{yearsInBusiness > 0 && (
					<Highlight
						textWrapper="body2"
						icon="date"
						text={`+${yearsInBusiness} year${
							yearsInBusiness > 1 ? 's' : ''
						} in business`}
					/>
				)}
				{languages && (
					<Highlight
						textWrapper="body2"
						icon="quick_reply"
						text={`Speaks ${languages}`}
					/>
				)}

				{totalMembers && (
					<div>
						<Icon name="guest_couple" size="md" />
						<Body2>
							{hasTeamMembers ? (
								<Link
									className={Style.link}
									href={'#navTeam'}
									to="navTeam"
									smooth
									spy
									isDynamic
									offset={-49}
								>
									{`${totalMembers} team members `}
								</Link>
							) : (
								<span>{`${totalMembers} team members `}</span>
							)}
						</Body2>
					</div>
				)}
			</div>
		);
	}

	return null;
};
