import { AboutCta } from '@components/about-cta/about-cta';
import { AboutSingleAward } from '@components/about-single-award/about-single-award';
import { ReadMore } from '@components/read-more/ReadMore';
import { TeamMemberModal } from '@components/team-member-modal/team-member-modal';
import { useIsVenue } from '@hooks/use-is-venue';
import { useAppSelector } from '@redux/hooks';
import type { Decorated } from '@typings/vendor';
import { Body1, H3, Overline } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { teamMembersAtom } from '../../jotai/storefront/organization';
import QuickResponderBadge from '../../pages/Storefront/containers/InlineRFQ/components/QuickResponderBadge';
import Social from '../../pages/Storefront/containers/contact/components/social';
import type { NavItemProps } from '../../pages/Storefront/containers/nav/types';
import Style from './about-summary.scss';
import { AboutHighlights } from './components/about-highlights/about-highlights';
import { AboutImage } from './components/about-image/about-image';

export const AboutSummary = ({ className }: NavItemProps) => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const vendorRaw = useAppSelector((state) => state.vendor.vendorRaw);

	const [expanded, setExpanded] = useState(false);
	const isVenue = useIsVenue();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openMemberCardModal = () => {
		vendor.bio?.description && setIsModalOpen(true);
	};

	const closeModalHandler = () => {
		setIsModalOpen(false);
	};
	const teamMembers = useAtomValue(teamMembersAtom);
	const hasTeamMembers = teamMembers.length > 0;

	const isPhotographer = vendor.categoryCode === 'WPH';
	const hasMainBioData =
		vendor.bio?.name &&
		vendor.bio?.photo?.url &&
		vendor.bio?.displayRole &&
		vendor.bio?.description;

	const shoudShowBioSpotlight =
		isPhotographer || (!isPhotographer && hasMainBioData && hasTeamMembers);

	const includeQuickResponder = vendorRaw?.vendorBehavior?.quickResponder;

	return (
		<>
			<div className={className}>
				<div className={Style.header}>
					<Social social={vendor.social} vendor={vendor} />
					<H3 className={Style.title}>
						About this {isVenue ? 'venue' : 'vendor'}
					</H3>
				</div>
				<div className={Style.content}>
					{shoudShowBioSpotlight && (
						<div className={Style.spotlight}>
							<div className={Style.spotlightUser}>
								<AboutImage imageUrl={vendor.bio?.photo?.url} />
								<div className={Style.belowImage}>
									<Body1
										bold
										className={`${Style.name} ${
											vendor.bio?.description ? Style.link : ''
										}`}
										onClick={openMemberCardModal}
									>
										{vendor.bio?.name || vendor.name}
									</Body1>
									{vendor.bio?.name && (
										<Overline>{vendor.bio.displayRole.toUpperCase()}</Overline>
									)}
								</div>
							</div>
							{includeQuickResponder && <QuickResponderBadge small />}
							<AboutCta />
						</div>
					)}
					<div className={Style.info}>
						<AboutSingleAward />
						<ReadMore
							clamp
							clampLines={6}
							expanded={expanded}
							setExpanded={setExpanded}
							linkColor="secondary"
							classes={{
								link: classNames(Style.readMoreLink, Style.forceUnderline),
							}}
						>
							<Body1 className={Style.desc}>{vendor?.description}</Body1>
						</ReadMore>
						<AboutHighlights />
					</div>
				</div>
			</div>
			{isModalOpen && vendor.bio?.description && (
				<TeamMemberModal memberBio={vendor.bio} onClose={closeModalHandler} />
			)}
		</>
	);
};
